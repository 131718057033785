import {
    setUnderbarWidth
} from "./helpers";

const $slideNavItems = $('.slide-nav-item'),
      $teamNavItems = $('.team-nav-item'),
      teamSelectNav = $('.team-select-nav');

let teamItems = document.querySelectorAll('.team-item');

$(document).ready(function() {

    //team page grid
    $('.team-item').matchHeight({
        byRow: false,
        property: 'height',
    });

    setInitialActiveTab( $('.slide-grid-nav' ) );

    // set active team tab on click
    $teamNavItems.click(function() {
        setTabPositionToLocalStorage( 'teamTabPositionId', $(this).attr('id'));
    });

    $slideNavItems.click(function() {
        let title = $(this).html();
        let $innerGrid = $('.slide-grid' );

        setActiveTabContent($innerGrid, title);
        setActiveTab( $slideNavItems, $(this), 'active-state' );
    });

    teamSelectNav.change(function() {
        let title = $(this).val();
        let $innerGrid = $('.slide-grid' );
        let tabIndex = $(this).children("option:selected").data('index');

        setActiveTabContent($innerGrid, title);
        setActiveTab( $slideNavItems, $(this), 'active-state' );
        setTabPositionToLocalStorage('teamTabPositionId', tabIndex);
    })


});

//sets about team red line on desktop navafter mobile nav is selected
$(window).on("resize", function() {
    let activeLinkWidth = $('.active-state').width();
    let $activeElement = $('.active-state');

    if ($activeElement.length) {
        let activeOffset = $activeElement.position().left;

        $('.underbar').stop(true).css({
            "left": activeOffset
        }, {
            duration: 200,
            queue: false
        });
    }
    $('.underbar').css({
        "width": activeLinkWidth
    }, {
        duration: 200,
        queue: false
    });
}).resize();

// set active tab
function setInitialActiveTab($innerGrid) {
    let tabPosition = '#nav-item-0';

    if (
        $innerGrid.hasClass('team-grid-nav')
        && sessionStorage.getItem('teamTabPositionId')
    ) {
        tabPosition = sessionStorage.getItem('teamTabPositionId');
    }

    let $activeTab = $(tabPosition);
    let title = $activeTab.html();

    if ( title === 'All' ) {
        sortGrid(teamItems,'data-last');
    }

    $activeTab.addClass('active-state');
    setActiveTabContent($innerGrid, title);
    setRedLinePosition($activeTab);
}

function setTabPositionToLocalStorage(itemName, value) {
    sessionStorage.setItem(itemName, '#' + value);
}

function setActiveTab($navItems, $targetElement, value) {

    $navItems.removeClass(value);
    $targetElement.addClass(value);
}

const $teamItem = $('.team-item');

// Sets the active team tab
function setActiveTabContent($innerGrid, title) {
    $innerGrid.fadeOut("300", function() {
        if ( title === 'Executives' ) {
            sortGrid(teamItems,'data-initial-order');
        } else {
            sortGrid(teamItems,'data-last');
        }

        if ( title === 'All' ) {
            $teamItem.show();

        } else {
            $teamItem.hide();
            $("div[data-title='" + title + "' ]").show();
            $(".mobile-select-nav").val(title);

        }

        $innerGrid.delay(300).fadeIn();

    });
}

// Sort item grid by data attribute value
function sortGrid(elementArray, sortValue) {
    Array.prototype.slice.call(elementArray).sort(function(a, b) {
        return a.getAttribute(sortValue).localeCompare(b.getAttribute(sortValue));

    }).forEach(function(currValue) {
        currValue.parentNode.appendChild(currValue);

    });
}

//set initial active red line position
function setRedLinePosition($activeElement) {
    let width = $activeElement.width();
    let $underbar = $('.underbar');

    if ( $activeElement.length ) {
        let activeOffset = $activeElement.position().left;

        $underbar.stop(true).css({
            "left": activeOffset
        });
    }
    setUnderbarWidth(width);
    $underbar.css({
        "width": width
    });
}

