// move to hovered link
export function slideToHover(that) {
    var linkWidth = $(that).width();
    var offset = $(that).position().left;
    $('.underbar').stop(true).animate({
        "left": offset
    }, {
        duration: 200,
        queue: false
    });
    $('.underbar').animate({
        "width": linkWidth
    }, {
        duration: 200,
        queue: false
    });
}

//move line to active link
export function slideToActive() {
    var activeLinkWidth = $('.active-state').width();
    var $activeElement = $('.active-state');

    if ($activeElement.length) {
        var activeOffset = $activeElement.position().left;


        $('.underbar').stop(true).animate({
            "left": activeOffset
        }, {
            duration: 200,
            queue: false
        });

    }
    $('.underbar').animate({
        "width": activeLinkWidth
    }, {
        duration: 200,
        queue: false
    });
}

// set underbar/link red line width
export function setUnderbarWidth(linkWidth) {
    $('.underbar').css({
        "width": linkWidth
    });
}

//fades awards slide in and out
export function fadeOutSlider( targetElement ) {
    targetElement.css({opacity: 1.0, visibility: "visible"}).animate({opacity: 0}, 100);
}

export function fadeInSlider( targetElement ) {
    targetElement.css({opacity: 0, visibility: "visible"}).animate({opacity: 1}, 100);
}
