import './modernizr-3.6.0.min.js';
import './plugins.js';
import 'es6-promise';
import AOS from 'aos';
import 'slick-carousel';
import 'sticky-kit/dist/sticky-kit';
import 'jquery-match-height';
import 'jqueryui';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import './float-labels';

import './teamGrid';
import './awards';
import './videoSlideshow';
import {
    slideToActive,
    slideToHover
} from "./helpers";

AOS.init({
    offset: 0,
    duration: 600,
    easing: 'ease',
    startEvent: 'load',
});

window.addEventListener('load', function() {
    AOS.refresh();
});

$(document).ready(function() {
    $('#footer').delay(1000).animate({opacity: 1}, 350);

    // careers accordion
    $('.accordion-item .item-title').click(function() {
        $(this).parent().siblings().removeClass("accordion-item-open");
        $(this).parent().toggleClass("accordion-item-open");
        $('.item-content-wrapper').stop().slideUp();
        $(this).siblings('.item-content-wrapper').stop().slideToggle();
    });

    function urlSegmentor(segment) {
        return location.pathname.split("/")[segment];
    }

    if ( urlSegmentor(1) !== '') {
        // Add top level active class
        navCurrent(
            $('nav ul li a[href^="/' + location.pathname.split("/")[1] + '"].nav-text'),
            'current-menu-item'
        );
    }

    if ( urlSegmentor(1) !== '' && urlSegmentor(1) !== 'sustainability' ) {
        // Add sub level active class
        navCurrent(
            $('.sub-menu li a[href^="/' + location.pathname.split("/")[1] + '"]'),
            'current-menu-item'
        );

        $(".current-menu-item").parent("li").parent(".sub-menu").siblings().addClass('current-menu-item');
    }

    if ( urlSegmentor(2) !== '' && urlSegmentor(1) === 'sustainability' ) {
        // Add sub level active class
        navCurrent(
            $('.sub-menu li a[href^="/sustainability/' + location.pathname.split("/")[2] + '"]'),
            'current-menu-item'
        );
    }

    function navCurrent($element, className) {
        $element.addClass(className);
    }


    /*
    * If we are accessing any non 'projects' entry, remove any instance
    * of project filter parameters
    */

    if ( urlSegmentor(1) !== 'projects') {
        localStorage.removeItem('regionCriteria');
        localStorage.removeItem('categoryCriteria');
        localStorage.removeItem('projectOrderUrls');
    }

    const navItems = [
        $('.slide-nav-item'),
        $('.awards-nav-item'),
        $('.insights-tab')
    ];

    navItems.forEach(
        element => element.on("mouseenter", function() {
            slideToHover(this);
        }).on("mouseleave", function() {
            slideToActive();
        })
    );

    // Check if gallery slider exists and load autoplay,
    // else load without autoplay
    if ( $('.gallery-slider').length > 0 ) {
        $('.gallery-slider .hero-inner').slick({
            arrows: false,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            pauseOnHover: false,
            fade: true,
            speed: 2000,
            autoplay: true,
            autoplaySpeed: 1000,
            infinite: true,
        });

    }

    $('.lower-content-slider .slider-wrapper').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        speed: 600,
        nextArrow: '<div class="next-arrow-wrapper"><div class="slider-arrow-next slider-arrow"></div></div>',
        prevArrow: '<div class="prev-arrow-wrapper"><div class="slider-arrow-prev slider-arrow"></div></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }, {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    $('.lower-content-slider .issues-slider-wrapper').slick({
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        speed: 600,
        nextArrow: '<div class="next-arrow-wrapper"><div class="slider-arrow-next slider-arrow"></div></div>',
        prevArrow: '<div class="prev-arrow-wrapper"><div class="slider-arrow-prev slider-arrow"></div></div>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            }, {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });


    $(window).on("resize", function() {
        $('.lower-content-slider .slider-wrapper').slick('resize');
        $('.lower-content-slider .issues-slider-wrapper').slick('resize');
    }).resize();

    // centers sliders arrows on project single page
    $(window).on("resize", function() {
        var textHeight = $('.slide-description').outerHeight() + 30;
        $('.prev-arrow-wrapper').css({
            'height': 'calc(100% - ' + textHeight + 'px)'
        });
        $('.next-arrow-wrapper').css({
            'height': 'calc(100% - ' + textHeight + 'px)'
        });
    }).resize();

    const $mobileToggle = $('.mobile-toggle');
    const $dropdownWithChildren = $(".menu-item-has-children");

    // Mobile nav
    $mobileToggle.click(function() {
        const $body = $('body');
        const $headerMenu = $('.header-menu');

        if ( $mobileToggle.hasClass('hamburger') ) {
            $mobileToggle.removeClass('hamburger');
            $mobileToggle.addClass('close-menu');
            $body.css('overflow', 'hidden');
            $headerMenu.slideDown();

        } else {
            $mobileToggle.addClass('hamburger');
            $mobileToggle.removeClass('close-menu');
            $body.css('overflow', 'auto');
            $headerMenu.slideUp();

            $dropdownWithChildren.not(this).find('.sub-menu').slideUp();
            $dropdownWithChildren.not(this).removeClass('sub-menu-open');

        }
    });

    $dropdownWithChildren.click(function() {
        const $self = $(this).find('.sub-menu');

        if ( $(this).hasClass('sub-menu-open') ) {
            $self.slideUp();

        } else {
            $self.slideDown();
        }

        $dropdownWithChildren.not(this).find('.sub-menu').slideUp();
        $dropdownWithChildren.not(this).removeClass('sub-menu-open');
        $(this).toggleClass("sub-menu-open");
    });

    $(window).resize(function() {
        const $body = $('body');
        const $headerMenu = $('.header-menu');

        if ($(window).width() > 1024) {
            $mobileToggle.addClass('hamburger');
            $mobileToggle.removeClass('close-menu');
            $body.css('overflow', 'hidden auto');

        } else {
            $mobileToggle.addClass('hamburger');
            $mobileToggle.removeClass('close-menu');
            $headerMenu.slideUp();
            $('.sub-menu').slideUp();
        }

    });

// desktop subnav
$(window).resize(function() {

    $("ul.dropdown li.menu-item-has-children").hover(function() {

        let subnav = $(this).find('.sub-menu');
        let subNavHeight = subnav.outerHeight() + 1;

    if ($(window).width() > 1024) {
            $(".menu-overlay").stop().fadeIn(200);

            $('.sub-menu-background').stop().animate({
                height: subNavHeight
            }, 200, function() {
            });

        }

    }, function() {

        if ($(window).width() > 1024) {

            $(".menu-overlay").stop(true, true).delay(200).fadeOut(200);

            $('.sub-menu-background').stop().animate({
                height: 0
            }, 200, function() {
            });

        }

    });

});

    //reset main nav menu for desktop on window scroll
    // $(window).resize(function() {
    //     if ($(window).width() > 1024) {
    //         $('.close-menu').hide();
    //     } else {
    //     }
    // });

    //reset menu for mobile on window scroll
    // $(window).resize(function() {
    //     if ($(window).width() < 1024) {
    //         $('.close-menu').hide();
    //         $('.sub-menu').slideUp();
    //     }
    // });

    var alterClass = function() {
        var ww = document.body.clientWidth;
        let $searchFieldWrapper = $('.search-field-wrapper');

        if (ww < 769) {
            $searchFieldWrapper.removeClass('search-field-desktop');
            $searchFieldWrapper.addClass('search-field-mobile');
        } else if (ww > 769) {
            $searchFieldWrapper.addClass('search-field-desktop');
            $searchFieldWrapper.removeClass('search-field-mobile');
        }
    };
    $(window).resize(function() {
        $('.search-field-wrapper').removeAttr("style");
        alterClass();
    });
    //Fire it when the page first loads:
    alterClass();
    // header search
    $('.search').on("click", function() {
        var toggleWidth = $(this).parent().siblings('.search-field-desktop').width() === 212 ? "0px" : "212px";
        $('.search-field-desktop').animate({
            width: toggleWidth
        });
        var toggleHeight = $(this).parent().siblings('.search-field-mobile').height() === 37 ? "0" : "37px";
        $('.search-field-mobile').animate({
            height: toggleHeight
        }, 'fast');
    });

    //project filter animation
    function deskTopOpen() {
        const filterMenuInner = '.filter-menu-inner';

        $('.project-grid').addClass('project-grid-open', 400);
        $('.about-team-grid').addClass('about-team-grid-open', 400);
        $('.filter-toggle').addClass('filter-toggle-open');

        $('.project-title').css("fontSize", "25px");
        $('.project-location').css("fontSize", "15px");

        $(filterMenuInner).show();
        $(filterMenuInner).css('opacity', '0');

        $('.filter-menu').animate({
            width: '250px'
        }, 400, function() {

            var menuHeight = $('#sticky-anchor').height();

            $('.project-grid, .about-team-grid').animate({
                'min-height': menuHeight + 100

            }, 500, function() {
                $('.filter-menu-inner').animate({opacity: 1}, 500);

            });

        });
    }

    function deskTopClose() {
        const $projectGrid = $('.project-grid');

        $('.filter-menu-inner').fadeOut('fast', function() {
            $('.filter-toggle').removeClass('filter-toggle-open');
            $('.project-title').removeAttr("style");
            $('.project-location').removeAttr("style");

            $('.filter-menu').animate({
                width: '0%'
            }).css('width', 'auto');
            $projectGrid.removeClass('project-grid-open', 400);
            $('.about-team-grid').removeClass('about-team-grid-open', 400);

            $projectGrid.animate({'min-height': '0'}, 500);
        });
    }

    function desktopInit() {
        if ($('.filter-menu').width() === 0) {
            deskTopOpen();
        } else {
            deskTopClose();
        }
    }

    function mobileOpen() {
        let $filterMenu = $('.filter-menu');

        $('.filter-menu-inner').show();
        $('.filter-toggle').removeClass('filter-toggle-open');
        $filterMenu.css('width', '100%');
        $filterMenu.slideDown();
        $filterMenu.addClass('filter-menu-open');
    }

    function mobileClose() {
        let $filterMenu = $('.filter-menu');

        $filterMenu.slideUp(function() {
            $filterMenu.css('width', '0').css('display', 'none');
        });
        $('.filter-menu-inner').show();
        $filterMenu.removeClass('filter-menu-open');
    }

    function mobileInit() {
        if ($('.filter-menu').is(':visible')) {
            mobileClose();
        } else {
            mobileOpen();
        }
    }

    $(window).resize(function() {
        let $filterMenu = $('.filter-menu');

        if ($(window).width() > 768) {
            $('.filter-menu').show();
        }
        if ($(window).width() < 768) {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            } else {
                $filterMenu.hide();
                $filterMenu.removeClass('filter-menu-open');
                $filterMenu.css('width', '0');
                $('.project-grid').removeClass('project-grid-open', 400);
            }
        }
    });
    $('.filter-toggle').on("click", function() {
        if ($(window).width() > 768) {
            desktopInit();

        } else {
            mobileInit();
        }
    });
    // Footer form placeholder text
    $('.e2ma_signup_form_element').children('#id_email').attr("placeholder", "Enter your email address").blur();
    // back to top button
    $(window).scroll(function() {
        if ($(this).scrollTop() > 1000) {
            $('.top-button').fadeIn();
        } else {
            $('.top-button').fadeOut();
        }
    });
    $('.top-button').click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    //Sort toggle click
    $('.sort-toggle span').click(function() {
        $('.sort-toggle span').removeClass('red-active');
        $(this).addClass('red-active');
    });
    $('.grid-sort-toggle span').click(function() {
        $('.grid-sort-toggle span').removeClass('red-active');
        $(this).addClass('red-active');
    });


    // stick filter to top of screen.
    $(window).on("resize", function() {
        var $sidebar = $('#sticky-anchor');

        if ($sidebar.length) {
            var top = $sidebar.offset().top - parseFloat($sidebar.css('margin-top'));

            $(window).scroll(function(event) {
                var y = $(this).scrollTop();

                if (y >= top) {
                    $sidebar.addClass('fixed');

                } else {
                    $sidebar.removeClass('fixed');

                }
            });

        }
    }).resize();

    // filter boxes check after hiitng back button
    $('.filter-checkbox').click(function(e) {
        if (e.target.checked) {
            sessionStorage.checked = true;
        } else {
            sessionStorage.checked = false;
        }
    });

    if ($('.filter-checkbox').is(':checked')) {
        if ($(window).width() > 768) {
            deskTopOpen();
        } else {
            mobileOpen();
        }
    }

    // Match Heights
    $('.article-project-item').matchHeight();
    //remove bottom border on last element
    $('.articles-grid-insights').children('.article-insights-item').last().css("border-bottom", "none");

    const $articlesNavItem = $('.articles-nav-item'),
          $articlesGridGroup = $('.articles-grid-group'),
          $articlesGridInner = $('.articles-grid-inner');


    $articlesNavItem.click(function() {
        //get first class name of link
        let $this = $(this);
        let classString = $this.attr('id');
        let thisLink = classString.replace(/nav-item-/, '');
        const $articleGridItem = $('.article-team-item');
        //get class name of section
        let gridSelector = $('#grid-' + thisLink);

        // get height of clicked section
        // $articleGridItem.matchHeight._update();

        let newHeight = gridSelector.height();
        $articlesGridGroup.fadeOut();

        // $articlesGridInner.delay(300).animate({
        //     'height': newHeight
        // }, 300, "easeInQuint");
        $articlesGridGroup.fadeOut();
        $(gridSelector).delay(300).fadeIn();

        // active state for clicked link
        $articlesNavItem.removeClass('active-state');
        $this.addClass('active-state');

        // show and hide sort function on desktop only
        if ($(window).width() > 768) {
            toggleProjectSortMenu($this);
        }
    });

    $(window).resize(function() {
        var $dateToggle = $('.date-toggle');

        if ( $(window).width() < 768 ) {
            $dateToggle.hide();
        } else {
            $dateToggle.show();
        }
    });


    function toggleProjectSortMenu($activeTab) {
        var $dateToggle = $('.date-toggle');

        if ( $activeTab.hasClass('projects-menu-link') && $(window).width() > 768 ) {

            $dateToggle.show();
        } else {
            $dateToggle.hide();
        }
    }

});
