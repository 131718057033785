import {
    fadeInSlider,
    fadeOutSlider,
    slideToActive
} from "./helpers";

$(document).ready(function () {
    const $awardSlider = $('.awards-slider'),
          $awardsMobileSelect = $('.awards-mobile-select'),
          $arrowNextWrapper = $('.next-arrow-wrapper'),
          $arrowPreviousWrapper = $('.prev-arrow-wrapper'),
          $underbar = $('.underbar');

    $awardSlider.on('beforeChange.slick', fadeOutSlider($awardSlider));
    $awardSlider.on('afterChange.slick', fadeInSlider($awardSlider));

    // about awards page slider
    // Home hero image slider
    $awardSlider.slick({
        slickFilter: '.slide-item',
        prevArrow: '.prev-arrow-wrapper',
        nextArrow: '.next-arrow-wrapper',
        dots: false,
        autoplay: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        fade: true,
        cssEase: 'ease-out',
        useTransform: true,
        adaptiveHeight: true,

    });

    const $awardNavItems = $('.awards-nav-item');

    setTabIndex($awardSlider, 0);

    $awardNavItems.click(function () {
        let slideIndex = $(this).index();

        setTabIndex($awardSlider, slideIndex);
        moveActiveClass($awardNavItems, $(this), 'active-state');
    });

    $awardsMobileSelect.on('change', function () {
        let slideIndex = $(this).children("option:selected").val();
        setTabIndex($awardSlider, slideIndex);
    });

    //awards slider next
    $arrowNextWrapper.click(function () {
        let nextDateActivePosition = $('.active-state').next().position().left;
        $('.active-state').next('li').addClass('active-state').prev('li').removeClass('active-state');

        moveUnderbar($underbar, nextDateActivePosition);

        $('.awards-mobile-select option:selected').next().attr('selected', 'selected');
    });
    //awards slider prev
    $arrowPreviousWrapper.click(function () {
        let prevDateActivePosition = $('.active-state').prev().position().left;
        $('.active-state').prev('li').addClass('active-state').next('li').removeClass('active-state');

        moveUnderbar($underbar, prevDateActivePosition);

        $('.awards-mobile-select option:selected').prev().attr('selected', 'selected');
    });
});

function setTabIndex($slider, slideIndex) {
    if ($slider.length) {
        $slider[0].slick.slickGoTo(parseInt(slideIndex));
    }
}

function moveActiveClass(allItems, targetElement, className) {
    allItems.removeClass(className);
    targetElement.addClass(className);
}

function moveUnderbar($bar, dateActivePosition) {
    $bar.stop(true).animate({
        "left": dateActivePosition
    }, {
        duration: 200,
        queue: false
    });
}
