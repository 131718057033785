// float labels
var form = '#catalystMagazineSubscription-form';

$(form + ' input,' + form + ' textarea').on('blur', function() {

    var $element = $(this).closest('.field');
    var activeClass = 'active';
    var fillClass = 'filled';

    console.log($element);

    removeClass(activeClass, $element);

    if ($(this).val() !== '') {
        addClass(fillClass, $element);

    } else {
        removeClass(fillClass, $element);
    }

}).on('focus', function() {
    var $element = $(this).closest('.field');

    addClass('active', $element);
});

function addClass(className, element) {
    $(element).addClass(className);
}

function removeClass(className, element) {
    $(element).removeClass(className);
}
